import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { map, isEmpty } from 'lodash/fp'
import classNames from 'classnames'

// Components
import { JobAd } from './JobAd'

// Styling
import './job-ads.scss'

// Utils
import { convertHexToRGBA } from 'utils/convertHexToRgba'
import { shadeColor } from 'utils/shadeColor'

// types
import { Job } from 'types/teamtailor'
import { BackgroundColors, ContentfulPage } from 'types/contentful'

interface Props {
  title?: string
  background: BackgroundColors | undefined
  children: React.ReactNode | React.ReactNode[]
}

type JobNode = {
  node: Partial<Job>
}

interface Query {
  jobAds: {
    edges: JobNode[]
  }
  careerPage: Partial<ContentfulPage>
}

export const JobAds: React.FC<Props> = ({ title, children, background }) => {
  const data = useStaticQuery<Query>(graphql`
    query jobAdsBlockList {
      jobAds: allTeamTailorJob(
        filter: { attributes: { status: { eq: "open" } } }
      ) {
        edges {
          node {
            id
            slug
            teamTailorId
            attributes {
              title
              pitch
            }
          }
        }
      }

      careerPage: contentfulPage(template: { eq: "Career" }) {
        slug
      }
    }
  `)

  const style = {
    backgroundColor: background,
  }

  const lightenBackground =
    background && shadeColor({ color: background, percent: 39 })

  const subSectionStyle =
    background && lightenBackground
      ? {
          backgroundColor: convertHexToRGBA({
            hex: lightenBackground,
            alpha: 0.6,
          }),
        }
      : undefined

  const Ads = !isEmpty(data.jobAds.edges)
    ? map(
        ({ node }) => (
          <JobAd
            key={node.id}
            title={node.attributes?.title || ''}
            href={`/${node.slug}`}
            text={node.attributes?.pitch || ''}
            prefixUrl={data.careerPage.slug}
          />
        ),
        data.jobAds.edges
      )
    : null

  const listClasses = classNames('jobads__list', {
    'jobads__list--empty': isEmpty(data.jobAds.edges),
  })

  return (
    <div className="block block--jobs jobads header-dark" style={style}>
      <div className={'jobads__subsection'} style={subSectionStyle}>
        <header className={'jobads__header'}>
          {title && <h3 className="jobads__title">{title}</h3>}
          <div className="jobads__content">{children}</div>
        </header>
        <div className={listClasses}>
          {Ads}
          {isEmpty(data.jobAds.edges) && (
            <p>
              Just nu har vi de kompetenser vi behöver på Camelonta, men du är
              varmt välkommen att skicka din ansökan ändå. Kontakta oss för jobb
              eller praktik via länkarna nedan.
            </p>
          )}
        </div>
      </div>

      <div className={'jobads__subsection'} style={subSectionStyle}>
        <header className={'jobads__header'}>
          <h3 className="jobads__title">{'Spontanansökan'}</h3>
        </header>
        <div className="jobads__list">
          <JobAd
            title="Hittar du inte rätt tjänst?"
            text="Ibland söker vi inte aktivt efter en specifik roll, men hör av dig till oss ändå. Vi är alltid nyfikna på nya talanger och nya människor!"
            buttonText={'Skicka in spontanansökan'}
            href={'mailto:career@camelonta.se'}
          />
          <div>
            <h3 className="jobAd__title">Söker du praktik?</h3>
            <p className="jobAd__pitch">
              För tillfället har vi inga praktikplatser tillgängliga, vi kommer
              öppna upp för nya ansökningar under våren 2025.
            </p>
          </div>
          {/* <JobAd
            title="Söker du praktik?"
            text="Se annonserna ovan"
            href={'mailto:career@camelonta.se'}
          /> */}
        </div>
      </div>
    </div>
  )
}
